<template>
  <div class="gry-bg py-5">

  </div>
</template>
<script>
import { login } from '@/api/login'
import {setToken, setUser, setUserId, setUserType} from '@/utils/auth'
export default {
  data() {
    return {
      form: {
        email: '',
        password: ''
      }
    }
  },
  mounted() {
    debugger
    console.log("this.$route.query.token")
    console.log(this.$route.query.token)
    setToken(this.$route.query.token)
    setUser(this.$route.query.username)
    setUserId(this.$route.query.id)
    setTimeout(() => {
      this.$router.push({
        name: 'index'
      })
    }, 200)
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
  }
}
</script>